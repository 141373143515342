/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { isEmpty, isInt } from "_dash";
import { CONTRACTS } from "global_constants";
import { getErrorMessage, getEtherscanTxUrl } from "utils";

import ProviderHOC from "hoc/provider";
import useProvider from "hooks/useProvider";
import BlueBananaSerum from "adpaters/blueBananaSerum";
import OmegaKongsClubMint from "adpaters/omegaKongsClubMint";

import Faq from "pages/sections/faq";
import Footer from "components/footer";
import ConnectWalletBtn from "components/connectWalletBtn";

function App({ provider }) {
  const contracts = useProvider(provider);
  const isConnected = !!provider?.address;

  const [kongs, setKongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [serumCount, setSerumCount] = useState(0);
  const [refreshCount, setRefreshCount] = useState(0);
  const [selectedKongs, setSelectedKongs] = useState([]);

  const [drinking, setDrinking] = useState(false);
  const [buyTxnHash, setBuyTxnHash] = useState();
  const [buyTxnError, setBuyTxnError] = useState();

  const [checkerValue, setCheckerValue] = useState(1);
  const [checkerKongId, setCheckerKongId] = useState(1);
  const [kongCanDrink, setKongCanDrink] = useState(false);

  useEffect(() => {
    if (!isEmpty(contracts)) {
      setLoading(true);
      const bananaSerum = new BlueBananaSerum(
        contracts[CONTRACTS.BLUEBANANASERUM],
        provider.address
      );
      const omegaKongClub = new OmegaKongsClubMint(
        contracts[CONTRACTS.OMEGAKONGSCLUBMINT],
        provider.address
      );
      bananaSerum.balanceOf().then((response) => {
        setSerumCount(response.toNumber());
      });
      omegaKongClub.getTokens().then((response) => {
        setKongs(response.filter((i) => i.toNumber()));
        setLoading(false);
      });
    }
  }, [contracts, refreshCount]);

  useEffect(() => {
    if (!isEmpty(contracts)) {
      new OmegaKongsClubMint(contracts[CONTRACTS.OMEGAKONGSCLUBMINT])
        .kongToSerumStatus(checkerKongId)
        .then((response) => {
          setKongCanDrink(!response);
        });
    }
  }, [contracts, checkerKongId]);

  function getKongImg(kongId) {
    return `https://storage.googleapis.com/alphakongclub/images/${kongId}.png`;
  }

  function onChange(isChecked, kongId) {
    if (isChecked && selectedKongs.length < serumCount) {
      let newKongs = [...selectedKongs];
      if (isChecked) {
        newKongs.push(kongId);
      } else {
        newKongs = newKongs.filter((i) => i !== kongId);
      }
      setSelectedKongs([...newKongs]);
    }
  }

  async function drinkSerum() {
    setBuyTxnError();
    if (!drinking && !loading && selectedKongs.length) {
      setDrinking(true);
      try {
        const tx = await new OmegaKongsClubMint(
          contracts[CONTRACTS.OMEGAKONGSCLUBMINT],
          provider.address
        ).serumMint(provider.signer, selectedKongs);
        setBuyTxnHash(tx.hash);
        await tx.wait();
        setSelectedKongs([]);
        setRefreshCount((_) => _ + 1);
      } catch (e) {
        setBuyTxnError(getErrorMessage(e));
      }
      setDrinking(false);
      setBuyTxnHash(undefined);
    }
  }

  return (
    <>
      <div className="app-container">
        <div className="flex flex-col flex-1 px-6">
          <div>
            <p className="text-6xl secondary-font uppercase mb-12 leading-tight">
              Drink your blue banana serum
            </p>
            <p className="text-white text-3xl mb-8">
              Each Blue Banana Serum gets you one Omega Kong NFT, you need at
              least one Alpha Kong NFT to use the blue banana serum.
            </p>
            <p className="text-white text-3xl">
              Once you drink the Blue Banana Serum, it gets burned and you get
              your Omega Kong(s). You get one Blue Banana Serum for each Alpha
              Kong NFT that you staked from 10 April to 18 April. You can
              already stake your Blue Banana Serum in the capsule to get 1 Alpha
              Coin per day. The BB Serum is a collection in itself, so it’s
              tradeable on the open market.
              <br />
              <br />
              If your NFTs don't show up, make sure to unstake your NFTs from
              the ecosystem.
            </p>
          </div>
          <div className="md:mt-32 mt-24">
            <div className="text-center">
              <p className="text-6xl mb-8">Drink your Serum here</p>
              <p className="text-3xl mb-12">
                Connect your wallet below to drink your Blue Banana Serum.
              </p>
              <div className="text-center flex gap-24 mb-12 flex-col md:flex-row">
                <div className="bg-lightBlack py-10 text-center md:w-1/2 w-full">
                  <p className="uppercase text-[16px] mb-2">Total serums</p>
                  <p className="text-6xl secondary-font text-primary">
                    {serumCount}
                  </p>
                </div>
                <div className="bg-lightBlack py-10 text-center md:w-1/2 w-full">
                  <p className="uppercase text-[16px] mb-2">Total kongs</p>
                  <p className="text-6xl secondary-font text-primary">
                    {kongs.length}
                  </p>
                </div>
              </div>
            </div>
            {isConnected ? (
              <>
                <div className="w-full mb-12">
                  <div className="flex gap-6 flex-wrap flex-col md:flex-row">
                    {kongs.map((item) => {
                      const kongId = item.toString();
                      const isChecked = !!selectedKongs.includes(kongId);
                      return (
                        <div key={kongId}>
                          <div
                            onClick={() => onChange(!isChecked, kongId)}
                            style={{
                              backgroundImage: `url(${getKongImg(kongId)})`,
                            }}
                            className="bg-no-repeat	md:bg-contain bg-cover md:w-[190px] w-full md:h-[190px] h-[250px] relative cursor-pointer"
                          >
                            <input
                              type="checkbox"
                              checked={selectedKongs.includes(kongId)}
                              onChange={(e) =>
                                onChange(e.target.checked, kongId)
                              }
                              className="absolute top-4 right-4 z-10 w-[20px] h-[20px]"
                              disabled={
                                !isChecked &&
                                selectedKongs.length === serumCount
                              }
                            />
                          </div>
                          <p className="text-5xl secondary-font uppercase mt-4 text-center">
                            #{kongId}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="text-center">
                  {drinking ? (
                    <p className="text-3xl">Transaction is loading ...</p>
                  ) : (
                    <div
                      onClick={() => drinkSerum()}
                      className="outline-btn primary"
                    >
                      {loading ? "Loading...." : "Drink serum"}
                    </div>
                  )}
                </div>
                {buyTxnHash ? (
                  <div className="mt-16">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={getEtherscanTxUrl(buyTxnHash)}
                      className="uppercase text-2xl white"
                    >
                      View TX
                    </a>
                  </div>
                ) : (
                  ""
                )}
                {buyTxnError ? (
                  <p className="text-3xl text-red-500 mt-8">{buyTxnError}</p>
                ) : (
                  ""
                )}
              </>
            ) : (
              <div className="text-center">
                <ConnectWalletBtn isPrimary />
              </div>
            )}
          </div>
          {isConnected ? (
            <div className="md:mt-32 mt-24">
              <p className="text-6xl secondary-font uppercase mb-12 leading-tight">
                Checker
              </p>
              <div className="w-[320px]">
                <img alt={checkerKongId} src={getKongImg(checkerKongId)} />
                <div className="uppercase mt-6 text-5xl flex justify-between">
                  <p className="secondary-font">#{checkerKongId}</p>
                  <p className="secondary-font" style={{marginLeft: "5px"}}>
                    {kongCanDrink ? "Can Still Drink" : "Cannot Drink"}
                  </p>
                </div>
                <div className="mt-12 flex justify-between gap-8">
                  <input
                    min={1}
                    max={8888}
                    type="number"
                    value={checkerValue}
                    onChange={(e) => setCheckerValue(e.target.value)}
                    className="text-primaryText py-2 px-4 text-4xl secondary-font w-[80%]"
                  />
                  <div
                    className="outline-btn primary mini-btn"
                    onClick={() => {
                      if (
                        isInt(checkerValue) &&
                        checkerValue > 0 &&
                        checkerValue <= 8888
                      ) {
                        setCheckerKongId(checkerValue);
                      }
                    }}
                  >
                    Fetch
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <Faq />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ProviderHOC(App);
